@import ../../styles/helpers

.title
    margin-bottom: 16px

.dateContainer
    margin-bottom: 16px

.subTitle
    font-size: 18px
    font-weight: 500

.row
    margin: 16px 0

.indentation
    margin-left: 16px

th
    border: 2px solid
    border-color: $white
    vertical-align: middle

td
    border: 2px solid
    border-color: $white
    text-align: right
    padding: 5px 5px
    
.pairNameCol
    text-align: center