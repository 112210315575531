@import ../../../styles/helpers

.container
    display: flex
    flex-direction: column
    gap: 44px
    +d
        padding: 0 40px
    +m
        padding: 0
    
.section    
    padding: 81px 68px
    background-color: #F4F5F6
    border-radius: 25px
    +dark
        background-color: #23262E
    +t
        padding: 62px 23px 52px 23px


.head
    display: flex
    flex-direction: column
    width: 100%
    text-align: center
    +m
        text-align: left
    +t
        text-align: center

.title
    margin-bottom: 28px
    +t
        margin-bottom: 20px
        font-size: 31.6px
        font-weight: 700
        line-height: 38.4px

.info
    margin-bottom: 44px
    +body-2
    color: $n4
    +t
        margin-bottom: 0


.wrapper
    margin: 0 -12px
    +m
        margin: 0 -4px

.btns
    text-align: center
    .button
        +m
            width: 100%
    +t
       display: none