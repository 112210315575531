@import ../../../styles/helpers

.main
    position: relative
    padding-top: 160px
    overflow: hidden
    +d
        padding-top: 80px
    +m
        padding-top: 32px
    

.main2
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    +t
        flex

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    +d
        max-width: 420px
        margin-bottom: 80px
    +m
        margin-bottom: 16px


.title
    margin-bottom: 20px
    +t
        text-align: center

.text
    margin-bottom: 32px
    +body-2
    color: $n4
    +t
        max-width: 300px
        text-align: center

.button
    margin-bottom: 80px
    +t
        margin-bottom: 0
        margin-left: auto

.scroll
    +d
        display: none !important
    
.bg
    position: absolute
    top: -145px
    right: calc(50% - 750px)
    width: 913px
    pointer-events: none
    +d
        top: -80px
        right: calc(50% - 550px)
        width: 600px
    +t
        right: calc(50% - 500px)
    +m
        position: static
        width: auto
        +s
            margin: 0 -64px
    img
        width: 100%
    
.topBanner
    display: flex
    align-items: center
    margin-bottom: 60px
    justify-content: space-between
    gap: 64.5px
    +t
        flex-direction: column
        align-items: center

.bannerImage
    +t
        width: 338px
      
