@import ../../styles/helpers

.form
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    margin: 20px 80px

.fieldset
    margin-bottom: 40px
    width: 50%
    
.field
    &:not(:last-child)
        margin-bottom: 32px

.label
    margin-bottom: 24px
    margin-top: 20px
    margin-left: 180px
    +hairline-2
    color: $n5

.labels
    margin-bottom: 24px
    margin-top: 20px
    margin-left: 40px
    +hairline-2
    color: $n5    

.variants
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    margin: 20px 80px

.variant
    display: flex
    flex-wrap: wrap
    justify-content: space-around


.card
    margin: 10px
    padding: 20px
    box-sizing: border-box

@media (max-width: 768px) 
  .card 
    width: 70%

.cards
    margin: 10px
    padding: 20px
    box-sizing: border-box

@media (max-width: 768px)   
  .form 
   display : block
   width: 100%    

@media (max-width: 1268px) 
   .inner_pending
    margin-right:220px
    margin-top: 0px

   .inner
    margin-right:220px



.radio
    position: relative
    flex: 0 0 calc(25% - 20px)
    width: calc(20% - 20px)
    margin: 0 10px
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0 
    &:checked + .inner
        box-shadow: inset 0 0 0 2px $p1
        +dark
            box-shadow: inset 0 0 0 2px $p1


.number
        background-color: $n7
        width:30px
        height:30px

.inner
    display: flex
    justify-content: center
    align-items: center
    height: 80px
    padding: 12px
    border-radius: 50px
    background: $n8
    background-color: #B1B5C3 
    transition: box-shadow .2s
    +m
        height: 48px
    &:hover
        box-shadow: inset 0 0 0 2px $n6
    +dark
        background: $n2
        &:hover
            box-shadow: inset 0 0 0 2px $n3
.inner_pending
    display: flex
    justify-content: center
    align-items: center
    height: 80px
   
    padding: 12px
    border-radius: 50px
    background: $n8
    background-color: #58BD7D 
    transition: box-shadow .2s
    +m
        height: 48px
    &:hover
        box-shadow: inset 0 0 0 2px $n6
    +dark
        background: #58BD7D
        &:hover
            box-shadow: inset 0 0 0 2px $n3   
.inner_sidebar
    display: flex
    justify-content: center
    align-items: center
    width: 70px
    height: 80px
    padding: 12px
    border-radius: 50px
    background: $n8
    background-color: #58BD7D 
    transition: box-shadow .2s
    +m
        height: 48px
    &:hover
        box-shadow: inset 0 0 0 2px $n6
    +dark
        background: #58BD7D
        &:hover
            box-shadow: inset 0 0 0 2px $n3            
.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 44px
    height: 44px
    margin-right: 14px
    border-radius: 50%
    +m
        display: none
    svg
        fill: $n8

.centerBtn
    text-align: center
.button
    margin-top:30px
    padding: 0 30px
    height: 40px
    text-align: center


    +d
        margin-bottom: 0

.text
    margin-right:25px
    +button-1
    color: $n2
    +dark
        color: $n8
    font-size:14px   
        

.btns
    text-align: right

.breadcrumbs
    margin-left: 40px
    +m
        margin: 16px 0 0

$theme:       #454cad  
$dark-text:   #5f6982  


// Upload Demo
// 
.imgContainer
  margin-left:0px
  margin-top: 70px
  width: 350px

.uploader 
  display: block  
  clear: both  
  margin: 0 auto  
  width: 100%  
  max-width: 600px  

  label 
    float: left  
    clear: both  
    width: 100%  


    padding: 2rem 1.5rem  
    text-align: center  
    background: transparent
    border-radius: 7px  
    border: 0.1px solid #5f6982 
    transition: all .2s ease  
    user-select: none  

    &:hover 
      border-color: $theme  
    
    &.hover 
      border: 3px solid $theme  
      box-shadow: inset 0 0 0 6px #eee  
      
      #start 
        i.fa 
          transform: scale(0.8)  
          opacity: 0.3  
  #start 
    float: left  
    clear: both  
    width: 100%  
    &.hidden 
      display: none  
    i.fa 
      font-size: 50px  
      margin-bottom: 1rem  
      transition: all .2s ease-in-out  
  #response 
    float: left  
    clear: both  
    width: 100%  
    &.hidden 
      display: none  
    #messages 
      margin-bottom: .5rem  
  #file-image 
    display: inline  
    margin: 0 auto .5rem auto  
    &.hidden 
      display: none  
    width:70px
    height:70px
    background-color: #5f6982
    border-radius: 60% 

  #notimage 
    display: block  
    float: left  
    clear: both  
    width: 100%  
    &.hidden 
      display: none  

  input[type="file"] 
    display: none  
  div 
    margin: 0 0 .5rem 0  
    color: $dark-text  
  
  .btn 
    display: inline-block  
    margin: .5rem .5rem 1rem .5rem  
    clear: both  
    font-family: inherit  
    font-weight: 700  
    font-size: 14px  
    text-decoration: none  
    text-transform: initial  
    border: none  
    border-radius: .2rem  
    outline: none  
    padding: 0 1rem  
    height: 36px  
    line-height: 36px  
    color: #fff  
    transition: all 0.2s ease-in-out  
    box-sizing: border-box  
    background: $theme  
    border-color: $theme  
    cursor: pointer  
.content_upload
    margin-top:30px
containerOptions
   width: 100px
   height: 200px
   background-color:#5f6982

   