@import '../../global.module.css';
/* Container styling */
.container {
    margin-top: 2rem;
    padding: 1rem;
    max-height: 100vh;
    background: url("/public/static/img/wave.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  /* Grid layout for the main content */
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  
  /* Left Section Styling */
  .leftSection {
    display: flex;
    flex-direction: column;
    /* background-color: #f4ecdc; */
  }
  
  .tabsContainer {
    /* background-color: #f4ecdc; */
    border-radius: 50px;
    padding: 1rem;
  }
  
  .tabs {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }
  
  .tabButton {
    background: none;
    border: none;
    text-transform: capitalize;
    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .tabButton:hover {
    background-color: var(--main-color);
  }
  
  .tabButton.active {
    background-color: var(--dark-blue); 
    color: var(--white);
  }
  
  /* Tab content styling */
  .tabContent {
    padding: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Right Section Styling */
  .rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% ;
  }
  
  .imageWrapper {
    position: relative;
    height: 100% ;
  }
  
  .imageWrapper img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  

  .darkMode {
    .tabButton{
        color: aliceblue;

        &:hover{
            background-color: var(--dark-blue);
            color: var(--white);
        }

        &.active{
            background-color: var(--main-color);
            color: var(--white);
        }
    }
  }