@import ../../../styles/helpers

.trades
    padding: 16px
    border-radius: 4px
    background: $n8
    +dark
        background: #17181B

.nav
    display: flex
    margin-bottom: 12px

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.table
    display: table
    width: 100%

.row
    display: table-row
    &:first-child
        .col
            padding-bottom: 12px
            +caption-bold-2
            color: $n4
    &:not(:first-child)
        .col
            +caption-2
            font-weight: 500
    &:nth-child(n+12)
        +d
            display: none

.col
    display: table-cell
    padding: 2px 4px
    &:first-child
        padding-left: 0
    &:nth-child(2)
        text-align: right
        padding-right: 12px
    &:last-child
        padding-right: 0
        text-align: right

.line
    display: inline-flex
    align-items: center

.favorite
    position: relative
    top: -2px
    margin-right: 4px

.info
    span
        color: $n4

.positive
    color: $p4

.negative
    color: $p3