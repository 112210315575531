@import '../../global.module.css';
.blogSection {
    padding: 40px 0;
    background-color: var(--light-background);
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  }
  
  .blockText {
    grid-column: span 12;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blockText h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .blockText p {
    font-size: 16px;
    color: #666;
  }
  
  .blogBox {
    grid-column: span 12;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .boxImage {
    position: relative;
  }
  
  .boxImage img {
    width: 100%;
    height: auto;
  }
  
  .wrapVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .wrapVideo button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .boxContent {
    padding: 20px;
  }
  
  .category {
    display: inline-block;
    background-color: var(--main-color);
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .title {
    font-size: 18px;
    color: #333;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
  }
  
  .meta {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
  }
  
  .meta a {
    text-decoration: none;
    color: #666;
  }
  
  .buttonLoadMore {
    grid-column: span 12;
    text-align: center;
    margin-top: 20px;
  }
  
  .buttonLoadMore a {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: var(--main-color);
    font-size: 16px;
  }
  
  .loading {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 800px;
    width: 100%;
  }
  
  .videoIframe {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
  }
  
  .videoIframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  @media (min-width: 768px) {
    .blogBox {
      grid-column: span 4;
    }
  }

  .dark{
    background-color: var(--dark-background);

    .blogBox{
      background-color: var(--light-dark-background);

      .title{
        color: var(--white);
      }
    }
  }