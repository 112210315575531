@import ../../styles/helpers

.title
    margin-bottom: 16px
    text-align: center

.dateContainer
    margin-bottom: 16px

.subTitle
    font-size: 18px
    font-weight: 500


.row
    margin: 16px 0

.indentation
    margin-left: 16px

ul
    list-style: disc
    margin-left: 24px

