@import '../../../global.module.css';
/* BasicTable.module.css */
.tableContainer {
    margin-top: 16px;
    border: 1px solid var(--light-gray);
    border-radius: 4px;
    overflow: hidden;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableHeaderRow {
    background-color: var(--dark-blue);
    color: var(--white);
  }
  
  .tableHeader {
    padding: 8px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
  
  .tableRow:nth-child(even) {
    background-color: var(--light-gray);
  }
  
  .tableCell {
    padding: 8px;
    font-size: 16px;
    text-align: left;
    border: 1px solid var(--light-gray);
  }
  


  .tableContainer.darkMode {
    
        border: 1px solid transparent;
        .tableHeaderRow{
            background-color: var(--main-color);
            /* background-color: var(--dark-blue); */
        }

        .tableRow{
            background-color: transparent;
        }

        .tableRow:nth-child(even) {
            background-color: var(--main-color-hover);
          }
    }
    