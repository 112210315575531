@import '../../../global.module.css';

.hero {
  position: relative;
  overflow: hidden;
  background: url("/public/static/img/wave.png") ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;
  /* margin-bottom: 100px; */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  position: relative;
  z-index: 11;
}

.textContainer {
  max-width: 800px;
  margin: 0 auto;
}

.heading {
  font-size: 35px;
  font-weight: bold;
  font-family: "Anton", sans-serif;
  text-transform: uppercase;
  color: #333; /* Replace with your secondary color */
  margin-bottom: 20px;
}

.subheading {
  font-size: 18px;
  color: #333; /* Replace with your secondary color */
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 20px;
}

.button {
  background-color: var(--main-color); 
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: #7a684a; /* Replace with your primary hover color */
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 510px;
  margin-top: 40px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.imageContainer::after {
  content: "";
  position: absolute;
  left: -180px;
  top: 150px;
  background-color: rgba(152, 134, 102, 0.1); 
  height: 1360px;
  width: 1360px;
  border-radius: 50%;
  z-index: 0;
}

.hero.darkMode{
  .heading{
    color: #fff;
  }
  .subheading{
    color: #fff;
    opacity: 0.5;
  }
}