@import ../../../../styles/helpers

.main
    margin-top: 32px

.cashReward
    display: flex
    gap: 32px
    align-items: center
    justify-content: space-between
    +a
        flex-direction: column-reverse

.cashRewardText
    display: flex
    gap: 40px
    flex-direction: column
    +a
        gap: 24px

.text
    display: flex
    gap: 16px
    flex-direction: column

.title
    color: $p1
    +m
        font-size: 40px

.subTitle
    font-size: 40px
    line-height: 52px
    +m
        font-size: 28px
        line-height: 40px

.description
    font-size: 20px
    line-height: 26px
    color: $n4

.button
    padding: 8px 16px
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    max-width: 200px
    border-radius: 22px
    background-color: $s3
    color: $n1
    cursor: pointer

.image
    +m
        height: 300px