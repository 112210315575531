@import ../../../../../../styles/helpers

.head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px

.title
    +body-bold-1

.counter
    +caption-bold-2
    svg
        margin-right: 4px

.field
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 12px

.fieldPadding
    padding: 10px 16px

.input
    flex-grow: 1
    width: 100%
    height: 48px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 14px
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.label,
.currency
    font-weight: 500
    color: $n4
    pointer-events: none

.button
    width: 100%

.dropdown
    margin-bottom: 12px

.checkboxes
    display: flex
    gap: 16px
    margin-bottom: 12px

.arrowButtons
    display: flex
    flex-direction: column
    padding-left: 8px

.arrowUpButton
    border-left: 2px solid $n6
    border-bottom: 2px solid $n6
    color: $n4
    padding: 2px
    +dark
        border-left: 2px solid $n2
        border-bottom: 2px solid $n2

.arrowDownButton
    border-left: 2px solid $n6
    color: $n4
    padding: 2px
    +dark
        border-left: 2px solid $n2

.fieldNumber
    display: flex
    align-items: center
    padding: 0 0 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    width: 100%
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 12px

.orderPrice
    display: flex
    gap: 8px
    label
        margin-bottom: 12px

.active
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $p1
    cursor: pointer
    color: $p1
    +dark
        box-shadow: inset 0 0 0 2px $p1
    &:not(:last-child)
        margin-bottom: 12px

.justifyDiv
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

.tpslContainer
    display: flex
    flex-direction: column
    gap: 6px
    margin-bottom: 14px