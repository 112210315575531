@import ../../../styles/helpers

.main
    display: flex
    justify-content: space-between
    align-items: center
    height: 100%
    +t
        flex-direction: column
        text-align: center

.image
    height: 600px
    width: auto
    +t
        width: 80%
        height: auto
        margin-top: 30px

.icon
    fill: white
    rotate: -90deg

.searchContainer
    display: flex
    border: 1px solid black
    border-radius: 25px
    align-items: center
    padding: 0 5px
    margin-top: 20px

.inputText
    background-color: transparent
    padding: 12px 10px
    width: 100%
    
.button
    border-radius: 50%
    color: $white
    background-color: sky-blue
    width: 35px
    height: 35px

.arrowButton
    background-color: blue
    border-radius: 50px

.info
    font-weight: 700
    color: $n3