@import '../../global.module.css';
.workSection {
    padding: 40px 0;
    background-color: var(--light-background);
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .blockText {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blockText h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .blockText p {
    font-size: 16px;
    width: 30%;
    margin: 0 auto;
    /* background-color: aqua; */
    color: #666;
    @media screen and (max-width: 768px) {
        font-size: 14px;
        width: 100%;
    }
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  }
  
  .workMain {
    grid-column: span 12;
  }
  
  .workBox {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .image {
    margin-bottom: 20px;
  }
  
  .rotateImg {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .rotateImg:hover {
    transform: rotate(360deg);
  }
  
  .content .step {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .content .title {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
  }
  
  .content .text {
    font-size: 14px;
    color: #666;
  }
  
  .connectImgDot {
    margin-top: 20px;
  }
  
  .line {
    width: 100%;
    height: auto;
  }
  
  @media (min-width: 768px) {
    .workMain {
      grid-column: span 6;
    }
  }
  
  @media (min-width: 1024px) {
    .workMain {
      grid-column: span 3;
    }
  }

  .dark{
    background-color: var(--dark-background);

    .workBox{
      background-color: var(--light-dark-background);

      .title{
        color: var(--white);
      }
    }
  }