@import '../../global.module.css';
.banner {
    padding: 2rem 1rem;
    display: flex;
    background-color: #FCFCFD !important;
  }
  
  .dark {
    background-color: #18191d;
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .grid {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  
  .banner__left {
    flex: 1;
    display: flex;
    align-items: start;
    justify-content: start;
    /* background-color: aqua; */
  }
  
  
  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .text {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  
  .btnAction {
    display: inline-block;
    background-color: #F0B90B;
    color: #fff;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .btnAction:hover {
    background-color: #282B26;
  }
  
  .banner__image {
    /* background-color: #F0B90B; */
    flex: 1;
    grid-column: span 12;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    img{
      width: 100%;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
  }
  
  .dark {
    background-color: #141416 !important;
  }