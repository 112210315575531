@import ../../styles/helpers

.title
    margin-bottom: 16px

.dateContainer
    margin-bottom: 16px

.subTitle
    font-size: 18px
    font-weight: 500


.row
    margin: 16px 0

.indentation
    margin-left: 16px
