:root {
  --main-color: #F0B90B;
  --main-color-hover: #282B26;
  --white: #F0ECE5;
  --dark-blue: #122C4f;
  --light-dark-blue: #122c4fa3;
  --gray: #888;
  --light-gray: #f5f5f5;
  --light-background: #FCFCFD;
  --dark-background: #141416;
  --light-dark-background: #18191D;

  --box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --main-border-radias: 24px;
  --transition: all 0.3s ease-in-out;

  /* Light mode colors */
  --background-color: #fff;
  --text-color: #000;
  --menu-background-color: #fff;
  --menu-text-color: #000;
}

/* Dark mode styles */
.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --menu-background-color: #1e1e1e;
  --menu-text-color: #ffffff;
}

html[data-theme="dark"],
body.chakra-ui-dark {
  background: #141416;
}