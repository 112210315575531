@import ../../../../../styles/helpers

.formContainer
    display: flex
    justify-content: center
    width: 100%
    

.form
    display: flex
    flex-direction: column
    gap: 16px
    text-align: left
    max-width: 400px
    width: 100%

.button
    width: fit-content
    align-self: center
