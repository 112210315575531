@import ../../../../styles/helpers

.banner
    display: flex
    align-items: center
    justify-content: center
    background-color: black
    border-radius: 25px
    padding: 0px 40px 0 77px
    +t
        font-size: 24px
        text-align: center
        gap: 14px
        padding: 35px 
        padding-bottom: 0
        flex-direction: column

.content
    display: flex   
    flex-direction: column  
    gap: 32px
    color: $white
    +t
        align-items: center
        gap: 14px

.title
    +t
        font-size: 36px
        font-weight: 700
        line-height: 35px

.subTitle
    color: $white
    +t
        font-size: 14px

.subTitle   
    color: $white
    b
        +dark
            color: $n8

.button
    padding: 15.5px 24px
    border-radius: 24px
    background-color: $p1
    color: $white
    width: fit-content
    font-weight: 700
    font-size: 16px
    line-height: 16px
    +dark
        background-color: $n2

.img
    +t
    height: 248px