@import ../../../styles/helpers

.top
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n3
    .info
        margin-bottom: 20px

.title
    margin-bottom: 32px
    text-align: center

.info
    text-align: center
    +caption-2
    color: $n4

.btns
    display: flex
    justify-content: center
    margin: 0 -10px
    +m
        margin: 0 -6px
    .button
        flex: 0 0 calc(50% - 20px)
        margin: 0 10px
        +m
            flex: 0 0 calc(50% - 12px)
            margin: 0 6px
        &:not(:last-child)
            margin-right: 12px
            +m
                margin-right: 6px
        
.field
    width: 100%
    &:not(:last-child)
        margin-bottom: 0px    

.form > .button
    width: 100%
    margin-top: 32px

.form > .info
    margin-bottom: 32px

.sendPinContainer
    display: flex   
    width: 100%
    justify-content: space-between
    
.errMessage
    padding: 10px
    background-color: #f8d7da
    border: 3px solid #f8d7da
    color: #721c24
    border-radius: 5px
    margin-bottom: 15px
    text-align: center
    font-weight: 600

.container
    display: flex
    flex-direction: column
    gap: 24px

.subActionButton
    margin-left: -12px
    z-index: 2
    border-top-left-radius: 3px
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px
    border-bottom-left-radius: 3px

.countryPhone
    display: flex
    gap: 12px

.phoneNumber
    display: flex
    flex-direction: column
    gap: 24px
    width: 100%

.emailPinError
    color: red
    text-align: center

.emailPinSuccess
    color: green
    text-align: center