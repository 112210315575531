@import '../../global.module.css';
/* .services {
    padding: 2rem 1rem;
    background-color: #141416;
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }
  
  .gridItem {
    grid-column: span 12;
  }
  
  @media (min-width: 768px) {
    .gridItem:first-child {
      grid-column: span 6;
    }
    .gridItem:last-child {
      grid-column: span 6;
    }
  }
  
  .servicesMain {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .servicesBox {
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #23262f;
    text-decoration: none;
  }
  
  .defHvr:hover {
    text-decoration: underline;
  }
  
  .text {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .blockText {
    text-align: center;
  }
  
  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .desc {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .btnAction {
    display: inline-block;
    background-color: #0056b3;
    color: #fff;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .btnAction:hover {
    background-color: #004080;
  }
   */

   .featuresContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background-color: var(--light-background) ;
  }
  
  .feature {
    background-color: var(--light-gray);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: 80%;
    max-width: 600px;
    text-align: center;
  }

  .feature .icon{
    margin: 0 auto;
    padding: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: fit-content;
}
  
  .feature h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature p {
    color: #666;
    font-size: 16px;
  }
  
  .cta {
    color: #fff;
    color: black;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    width: 80%;
    max-width: 600px;
    text-align: center;
  }
  
  .cta h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .cta p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .tradeButton {
    background-color: var(--main-color);
    color: var(--white);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}
  
  .tradeButton:hover {
    background-color: var(--main-color-hover);
  }

  .dark.featuresContainer {
    background-color: var(--dark-background);
    .feature{
        background-color: var(--light-dark-background);
        h2{
            color: var(--white);
        }
    }
    .cta{
        color: var(--white);
    }
  }