@import ../../styles/helpers

.title
    margin-bottom: 32px
    padding-right: 56px
    +m
        +poppins
        font-size: 24px
        line-height: 32px
        font-weight: 600
    svg
        position: relative
        top: -2px
        margin-right: 16px
        +m
            top: 0
        +dark
            fill: $n8

.dropdown
    flex-shrink: 0    
    width: 100%
    margin: auto
    margin-bottom: 20px
    +m
        width: 100%
        margin: 40px 0 0

.dropdownHead
    background: $n7
    box-shadow: inset 0 0 0 2px $n7
    border-radius: 24px
    +dark
        background: $n2
        box-shadow: inset 0 0 0 2px $n2

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.field,
.wrap,
.box,
.checkbox
    &:not(:last-child)
        margin-bottom: 32px

.wrap
    display: flex
    padding: 20px 24px
    border-radius: 4px
    background: $n7
    +dark
        background: $n3

.category
    margin-right: auto
    font-weight: 500
    color: $n3
    +dark
        color: $n7

.details
    text-align: right

.currency
    +body-bold-2

.price
    color: $n4

.box
    position: relative
    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px
        border-radius: 12px

.withdraw > .button
    width: 100%

.qrWrapper
    display: flex
    justify-content: center
    margin-bottom: 20px

.note
    +caption-3
    font-weight: 500
    color: $n4
    text-align: left

.noteWrapper
    margin-bottom: 20px

.addressText
    margin-bottom: 20px
    border-color: #353945
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid #E6E8EC
    font-family: "Poppins", sans-serif
    font-size: 14px
    line-height: 1.7142857143
    font-weight: 500
    transition: border-color 0.2s
