.rtl {
  direction: rtl;

  @media only screen and (max-width: '1339px') {
    .wrapper {
      padding-right: 0 !important;
      border: none !important;
    }

    .footer-col {
      &:first-child {
        padding-right: 0;
        padding-left: 64px;
      }

      &:nth-child(3) {
        padding-left: 0;
        padding-right: 64px;
      }
    }
  }

  @media only screen and (max-width: '1260px') {
    .custom-item {
      &:after {
        left: -66px !important;
      }
    }
  }

  @media only screen and (max-width: '1179px') {
    .item,
    .nav > div:not(:last-child) {
      margin: 0 0 0 32px !important;
    }

    .settings {
      margin: 0 0 0 16px !important;
    }

    .layout-head,
    .activity,
    .notifications,
    .control .button,
    .theme {
      margin: 0 0 0 16px !important;
    }

    .custom-item {
      &:after {
        left: -50px !important;
      }
    }
  }

  @media only screen and (max-width: '1023px') {
    .logo {
      margin: 0 0 0 16px !important;
    }

    .item,
    .nav > div:not(:last-child) {
      margin: 0 0 0 20px !important;
    }

    .settings {
      margin: 0 0 0 4px !important;
    }

    .layout-head,
    .activity,
    .notifications,
    .control .button,
    .theme {
      margin: 0 0 0 8px !important;
    }

    .footer-col {
      &:first-child {
        padding-right: 0;
        padding-left: 32px;
      }

      .logo {
        margin: 0 0 0 16px;
      }

      &:nth-child(3) {
        padding-left: 0;
        padding-right: 32px;
      }
    }

    .custom-item {
      &:after {
        left: -79px !important;
      }
    }
  }

  @media only screen and (max-width: '767px') {
    .logo {
      margin: 0 0 0 auto !important;
    }

    .item,
    .nav > div:not(:last-child) {
      margin: 0 !important;
    }

    .layout-head,
    .activity,
    .notifications,
    .control .button,
    .theme {
      margin: 0 0 0 12px !important;
    }

    .footer-col {
      .logo {
        margin: 0 0 0 auto;
      }
    }

    .custom-item {
      &:after {
        left: 0px !important;
      }
    }
  }

  .logo {
    margin: 0 0 0 32px;
  }

  .wrapper {
    border-right: 1px solid;
    border-left: none;
    padding: 0 32px 0 0;
  }

  .wrap {
    margin: 0 0 0 auto;
  }

  .item,
  .nav > div:not(:last-child) {
    margin-right: 0 !important;
    margin-left: 40px;
  }

  .settings {
    margin: 0 0 0 32px;

    .col:first-child {
      border-left: 1px solid;
      border-right: none;
    }
  }

  .layout-head,
  .activity,
  .notifications,
  .control .button,
  .theme {
    margin: 0 0 0 24px;
  }

  .footer-col {
    &:first-child {
      padding-right: 0;
      padding-left: 86px;
    }

    .logo {
      margin: 0 0 0 32px;
    }

    &:nth-child(3) {
      padding-left: 0;
      padding-right: 86px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      border-right: 1px solid;
      border-left: none;
    }
  }

  .copyright {
    margin-right: 0;
    margin-left: auto;
  }

  .footer-social:not(:last-child) {
    margin: 0 0 0 24px;
  }

  .exchange {
    .box:not(:last-child) {
      margin: 0 0 0 32px;
    }

    .list {
      margin-left: 0;
      margin-right: auto;

      .item {
        padding-left: 24px;
        padding-right: 0;

        &:not(:last-child) {
          margin-left: 24px;
          margin-right: 0;
          border-left: 1px solid;
          border-right: none;
        }
      }

      .subtitle svg {
        margin-left: 4px;
        margin-right: 0;
      }
    }

    .favorite {
      margin-left: 4px;
      margin-right: 0;
    }

    .spot {
      .nav {
        margin-left: auto;
        margin-right: 0;

        .link:not(:last-child) {
          margin-right: 0;
          margin-left: 16px;
        }
      }

      .info {
        a {
          margin-right: 8px;
          margin-left: 0;
        }
      }

      .counter {
        svg {
          margin-left: 4px;
          margin-right: 0;
        }
      }

      .checkbox-tick {
        margin-left: 12px;
        margin-right: 0;
      }

      .fieldNumber {
        padding: 0 16px 0 0;

        .arrowButtons {
          padding-right: 8px;
          padding-left: 0;

          .arrowUpButton {
            border: none;
            border-right: 2px solid;
            border-bottom: 2px solid;
          }

          .arrowDownButton {
            border: none;
            border-right: 2px solid;
          }
        }
      }

      .dropdown {
        .arrow {
          right: auto;
          left: 8px;

          .adornmentText {
            padding-right: 0;
            padding-left: 20px;
          }
        }

        .head {
          padding-right: 16px;
        }
      }
    }

    .futures {
      .isolated {
        border-left: 1px solid #777e90;
        border-right: none;
      }

      .leverage {
        svg {
          rotate: 180deg;
        }
      }
    }
  }

  .bg {
    left: 0;
    right: auto;
  }

  .market-first-column {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .market-last-column {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .currency-icon {
    margin-right: 0;
    margin-left: 32px;
  }

  .panel-title {
    margin-left: 12px;
    margin-right: 0;
  }

  .panel-button {
    margin-left: 0;
    margin-right: 12px;
  }

  .panel-chart {
    margin-right: 12px;
    margin-left: 0;
  }

  .fav {
    margin-left: 10px;
    margin-right: 0;
  }

  .form {
    text-align: right;
  }

  .custom-item {
    &:after {
      left: -84px;
      right: auto;
    }

    &:last-child {
      .custom-preview {
        &:before {
          display: none;
        }

        &:after {
          display: block;
        }
      }
    }

    &:first-child {
      .custom-preview {
        &:after {
          display: none;
        }

        &:before {
          display: block;
        }
      }
    }

    .title {
      text-align: right;
    }

    .content {
      text-align: right;
    }

    .banner-image {
      transform: scaleX(-1);
    }

    .panel-button {
      margin-right: 12px;
    }

    .auther-badge {
      margin-right: 0;
      margin-left: 12px;
    }

    .orders-tab {
      margin-left: 20px;
      margin-right: 0 !important;
    }
  }

  .indentation {
    margin-right: 16px;
    margin-left: 0;
  }

  .listItem {
    margin-right: 24px;
    margin-left: 0;
  }
}

.ltr {
  direction: ltr;
}
