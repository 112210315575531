@import ../../../../../styles/helpers

.pannel
    background-color: #F1F2F4
    border-radius: 8px
    margin-bottom: 16px
    display: flex
    padding: 16px
    font-weight: 400
    font-size: 14px
    line-height: 1
    text-align: center
    width: 100%
    cursor: pointer
    +dark
        background-color: $n1
    
.isolated
    width: 100%
    border-right: 1px solid $n4
    position: relative
    color: $n1
    +dark
        color: $white
    
.leverageL
    width: 100%
    border-right: 1px solid $n4
    color: $p4

.leverageS
    width: 100%
    color: $p3

.leverage
    width: 100%
    color: $n1
    +dark
        color: $white

.options
    position: absolute
    top: 36px
    left: -8px
    padding: 12px 0
    border-radius: 8px
    width: 100%
    background-color: #F1F2F4
    +dark
        background-color: $n1

.option
    padding: 12px 0
    &:hover
        background-color: $n6
        +dark
            background-color: $n2

span
    display: flex
    justify-content: center
    width: 100%

.currencyContainer
    display: flex
    align-items: center
    gap: 10px

.badgeGreen
    background-color: rgba(88, 189, 125, 0.15)
    border-radius: 2px
    color: $p4
    padding: 2px

.badgeRed
    background-color: rgba(255, 104, 56, 0.15)
    border-radius: 2px
    color: $p3
    padding: 2px

.button
    width: 100%
    background-color: $white
    padding: 10px
    border-radius: 20px