@import ../../../../../styles/helpers

.card
    padding: 32px
    border-radius: 16px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 40px
    background-color: $n6
    +dark
        background-color: $n2
    +a
        flex-direction: column

.content
    display: flex
    align-items: center
    justify-content: space-between
    gap: 40px
    +a
        flex-direction: column

.button
    padding: 8px 32px
    border-radius: 4px
    background-color: $s3
    color: $n1
    cursor: pointer

.progressContent
    display: flex
    gap: 16px
    flex-direction: column
    +a
        align-items: center
        text-align: center

.cashReward
    display: flex
    align-items: center
    gap: 8px
    border-radius: 4px
    padding: 6px 6px 6px 12px
    background-color: $s3
    color: $n1
    width: fit-content

.cashRewardText
    border-radius: 4px
    padding: 4px 8px
    background-color: $n2
    color: $n6
    +dark
        background-color: $n7
        color: $n1

.text
    font-size: 20px
    line-height: 26px

.progress
    display: flex
    gap: 8px
    align-items: center
    white-space: nowrap
    +a
        width: 100%

.progressBar
    width: 100%
    height: 6px
    border-radius: 4px
    background-color: $n4

.description
    color: $n4

.container
    display: flex
    gap: 24px
    flex-direction: column