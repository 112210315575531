@import ../../../styles/helpers
.main
    margin-bottom: 136px
    +d
        margin-bottom: 80px
    +m
        margin-bottom: 32px
.imageList
    display: flex
    justify-content: center
    align-items: center
    gap: 64px
    +s
        flex-direction: column
        gap: 24px
    +t
        width: 100%
.imageItem
    display: flex
    align-items: center
    flex-direction: column
    gap: 8px
    +t
        width: 100%
.image
    width: 100%
    border-radius: 8px
    cursor: pointer
    +s
        width: 100%
.label
    font-size: 16px
    font-weight: 500
    +s
        font-size: 20px