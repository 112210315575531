.liquidityContainer {
    background: #000;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
    align-items: center;
    width: 100%;
  }
  
  .textContent {
    grid-column: span 5;
  }
  
  .textStack {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 12px;
  }
  
  .heading {
    font-family: Anton, sans-serif;
    font-size: 50px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
  }
  
  .description {
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    margin: 0;
  }
  
  .buttonStackDesktop {
    display: flex;
    gap: 16px;
  }
  
  .buttonStackMobile {
    display: none;
    flex-direction: column;
    gap: 16px;
  }
  
  .startButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    text-transform: capitalize;
  }
  
  .startButton:hover {
    background-color: #0056b3;
  }
  
  .imageContent {
    grid-column: span 7;
  }
  
  .imageWrapper {
    position: relative;
    width: 100%;
    height: 610px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  
  .artboardImage {
    object-fit: cover;
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .textContent {
      grid-column: span 12;
    }
  
    .imageContent {
      grid-column: span 12;
    }
  
    .buttonStackDesktop {
      display: none;
    }
  
    .buttonStackMobile {
      display: flex;
    }
  }
  