@import ../../../styles/helpers

.main
    display: flex
    flex-direction: column


.bannerImage
    height: 400px
    +t
        height: 200px

.referralContainer 
    display: flex
    flex-direction: row-reverse
    align-items: center
    color: white 
    +t
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center


.earnCommissionContainer
    display: flex
    flex-direction: column
    gap: 32px
    +t
        text-align: center

.title
    margin: 60px auto 24px
    +t
        font-size: 26px

.subTitle
    +t
        font-size: 14px

.earnCommissionTitle
    +t
        font-size: 26px


.commissionImgContainer
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 32px

.commissionImgItem
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

.commisionTitle
    margin-top: 20px

.productAdvantageItemTitle
    width: 250px
    font-size: 16px
    line-height: 22px
    font-weight: 600
    margin-bottom: 12px

.productAdvantageItemContent
    width: 240px
    font-size: 14px
    line-height: 24px
    color: $n4

.productAdvantageItemContainer
    display: flex
    +t
        flex-direction: column
        align-items: center
        text-align: center
    justify-content: space-between

.brokerProductTitle
    margin-bottom: 32px
    +t
        font-size: 26px
        text-align: center

.productAdvantageItem
    display: flex   
    flex-direction: column  
    align-items: flex-start
    +t
        align-items: center
        gap: 20px
        margin-bottom: 30px

    gap: 40px
