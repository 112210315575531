@import ../../styles/helpers

.loader
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    +t
        height: 100%
        padding-top: 56px
    +dark
        background: #18191D
        +t
            background: none

.glow
    margin: 0 0 0 16px
    width: 16px
    height: 16px
    border-radius: 50%
    background: $n6
    animation: glow 1.2s ease-in-out infinite
    +dark
        background: $n3
    @keyframes glow
        0%
            transform: scale(0)
        50%
            transform: scale(1)
        100%
            transform: scale(0.1)