@import ../../../../styles/helpers

.section
    display: flex
    flex-direction: column
    gap: 54px

.tabs
    display: flex
    gap: 16px
    +s
        flex-direction: column
        text-align: center
    
.tab
    padding: 8px 16px
    border-radius: 4px
    cursor: pointer
    background-color: $n6
    +dark
        background-color: $n2

.active
    color: $p1