@import ../../../../styles/helpers

.card
    width: 100%
    border-radius: 40px
    overflow: hidden
    background: $n8
    text-align: center
    transition: all .2s
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +dark
        background: $n2
        box-shadow: inset 0 0 0 2px $n2
        &:hover
            background: transparent

.preview
    display: flex
    justify-content: center
    height: 185px
    padding-top: 20px
    
.title
    margin-top: 34px
    font-size: 16px
    font-weight: 500
    line-height: 24px

.content
    color: $n4
    font-size: 14px
    font-weight: 400
    line-height: 24px
    margin-bottom: 24px

.image
    width: 100%