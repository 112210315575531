@import ../../../../styles/helpers

.banner
    display: flex
    align-items: center
    justify-content: center
    gap: 3rem
    +t
        font-size: 24px
        text-align: center
        gap: 32px
        flex-direction: column-reverse

.content
    display: flex   
    flex-direction: column  
    gap: 32px
    +t
        align-items: center

.title
    +t
        font-size: 24px

.subTitle
    color: $n4
    +t
        font-size: 18px

.subTitle   
    b
        color: $n1
        +dark
            color: $n8

.button
    padding: 8px 16px
    border-radius: 4px
    background-color: $n6
    color: $p1
    width: fit-content
    +dark
        background-color: $n2

.bannerImage
    +m
        height: 378px