@import ../../styles/helpers

.page
    display: flex
    flex-direction: column
    min-height: 100vh
    overflow: hidden

.inner
    display: flex
    flex-direction: column
    flex-grow: 1

.innerStickyHeader
    display: flex
    flex-direction: column
    flex-grow: 1
    padding-top: 120px
