@import ../../../../../styles/helpers

.pannel
    background-color: #F1F2F4
    border-radius: 8px
    margin-bottom: 16px
    width: 100%
    +dark
        background-color: $n1

.head
    padding: 8px
    display: flex
    gap: 8px
    font-weight: 400
    font-size: 14px
    line-height: 1
    text-align: center
    width: 100%

.openClose
    padding: 8px 20px
    cursor: pointer
    width: 100%
    color: $n1
    +dark
        color: $white

.openActive
    border-radius: 8px
    background-color: $p4
    padding: 8px 20px
    cursor: pointer
    width: 100%
    color: $white

.closeActive
    border-radius: 8px
    background-color: $p3
    padding: 8px 20px
    cursor: pointer
    width: 100%
    color: $white