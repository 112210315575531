@import ../../styles/helpers

.details
  display: flex
  gap: 10px
  align-items: center

.adjustLeverageContainer
  display: flex 
  flex-direction: column
  gap: 24px

p 
  text-align: center

.badge
  background-color: $n4
  padding: 0 4px
  border-radius: 2px

.badgeGreen
    background-color: rgba(88, 189, 125, 0.15)
    border-radius: 2px
    color: $p4
    padding: 0 4px

.badgeRed
    background-color: rgba(255, 104, 56, 0.15)
    border-radius: 2px
    color: $p3
    padding: 0 4px

.button
    width: 100%
    background-color: $n2
    color: black
    padding: 10px
    border-radius: 20px

.leveragesContainer
  display: flex
  gap: 12px
  cursor: pointer
  max-width: 100%
  border: 1px solid $white
  padding: 8px
  border-radius: 6px
  overflow: hidden

.selectedLeverage
  background-color: $n4
  padding: 0 6px
  border-radius: 20px
  color: $n1
  font-weight: 500

.leverage
  background-color: transparent
  padding: 0px 4px
  border-radius: 4px
  color: $white