@import ../../../../styles/helpers

.contactUsContainer
    display: flex   
    text-align: center
    justify-content: space-between
    align-items: center
    +t
        flex-direction: column-reverse

.contactUsTitle
    text-align: center
    margin-bottom: 32px
    +t
        font-size: 24px

