.container {
    position: relative;
    overflow: hidden;
    background-color: var(--main-color); 
    display: flex;
    /* align-items: center; */
    min-height: 100vh;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/static/img/wave-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .leftSection {
    flex: 1;
    margin-left: 2rem;
    max-width: 50%;
    margin: auto 2rem;

  }
  
  .heading {
    font-family: "Anton", sans-serif;
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .gridItem {
    text-align: center;
  }
  
  .counter {
    font-family: "Anton", sans-serif;
    color: white;
    font-size: 2.5rem;
  }
  
  .detail {
    font-size: 1rem;
    color: white;
  }
  
  .rightSection {
    flex: 1;
    max-width: 50%;
    position: relative;
    display: none; /* Initially hidden on smaller screens */
  }
  
  .imageWrapper {
    position: relative;
    /* background-color: aqua; */
    width: 100%;
    height: 100% !important;
  }
  
  .image {
    position: absolute;
    right: 0;
    /* background-color: red; */
    /* object-fit: contain; */
    /* object-fit: cover; */
    /* width: 100%; */
    height: 100%;
  }
  
  @media (min-width: 768px) {
    .rightSection {
      display: block; /* Shown on medium to larger screens */
    }
  
    .leftSection {
      max-width: 40%;
    }
  
    .heading {
      font-size: 3rem;
    }
  
    .counter {
      font-size: 3rem;
    }
  }