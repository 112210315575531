@import '../../global.module.css';

.appBar {
  position: sticky !important;
  top: 0;
  background-color: var(--background-color);
  box-shadow: none;
  z-index: 1000;
}



.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

.logoLink {
  display: flex;
  align-items: center;
}

.mobileMenu {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.menuIcon {
  font-size: 24px;
  color: var(--text-color);
}

.mobileMenuContent {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  background-color: var(--menu-background-color);
  box-shadow: var(--box-shadow);
  z-index: 1000;
}

.mobileMenuItem {
  padding: 12px 16px;
  text-align: center;
  color: var(--menu-text-color);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.desktopMenu {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
}

.menuLink {
  text-decoration: none;
  color: var(--text-color);
  text-transform: capitalize;
  font-size: 16px;
  transition: var(--transition);

  &:hover {
    color: var(--main-color);
    transform: translateY(-5px);
  }
}



.contactButton {
  @media (max-width: 768px) {
    display: none !important;
  }
  display: flex;
  align-items: center;
  gap: 50px;
}

.fab {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 16px;
  transition: var(--transition);

  &:hover {
    background-color: var(--main-color-hover);
  }
}

.themeToggle{
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.appBar.darkMode {
  background-color: #141416;
  box-shadow: var(--box-shadow);
  .mobileMenuItem{
    background-color: #141416;
  }
  .mobileMenuItem,
  .menuLink{
    color: #fff !important;
    &:hover{
      color: var(--main-color) !important;
    }
  }
  .menuIcon{
    color: #fff !important;
  } 
}

/* Hide mobile logo by default */
.mobileLogo {
  display: none;
}

.desktopLogo {
  margin: 0 10px;
}

/* Hide desktop logo on mobile/tablet */
@media (max-width: 768px) {
  .desktopLogo {
    display: none;
  }
  .mobileLogo {
    display: block;
    margin: 0 10px;
    height: 30px !important;
  }

  .registerButton{
    margin-left: 2px;
  }
}