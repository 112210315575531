@import ../../../../../styles/helpers

.container
    display: flex
    flex-direction: column
    gap: 64px

.content
    display: flex
    flex-direction: column
    align-items: center
    gap: 34px

.desc
    display: flex
    flex-direction: column
    align-items: center

.text
    color: $n4

.description
    color: $n4