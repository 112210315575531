@import '../../global.module.css';
.aboutSection {
    padding: 40px 0;
    background-color: var(--light-background);
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .grid {
    /* display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  
  .leftColumn {
    grid-column: span 12;
    /* background-color: blueviolet; */
    flex: 1;
    @media screen and (max-width: 768px) {
        display: none;
    }
  }
  
  .rightColumn {
    grid-column: span 12;
    flex: 1;
    display: flex;
    align-items: start;
    justify-content: start  ;
    /* background-color: blueviolet; */

  }
  
  .aboutImage {
    position: relative;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .tradersBox {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .tradersBox_1{
    top: 20%;
    left: 20%;
  }

  .tradersBox_2{
    bottom: 20%;
    left: 20%;
  }

  .tradersBox_3{
    top: 50%;
    right: 0%;
  }
  
  .tradersBox .icon {
    display: flex;
    align-items: center;
  }
  
  .tradersBox .content h6 {
    font-size: 18px;
    margin: 0;
  }
  
  .tradersBox .content p {
    font-size: 14px;
    margin: 0;
  }
  
  .aboutContent h3 {
    font-size: 28px;
    margin-bottom: 20px;
    /* color: var(--white); */
  }
  
  .aboutContent p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .list {
    list-style: none;
    padding: 0;
  }
  
  .list li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .list .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    height: 70px;
    width: 70px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    img{
        height: 50px;
    }
}
  
  .list .content h6 {
    font-size: 18px;
    margin: 0;
  }
  
  .list .content p {
    font-size: 14px;
    margin: 0;
  }
  
  .green {
    color: green;
  }
  
  .blue {
    color: blue;
  }
  
  @media (min-width: 768px) {
    .leftColumn {
      grid-column: span 6;
    }
  
    .rightColumn {
      grid-column: span 6;
    }
  }

  .dark{
    background-color: var(--dark-background);
  }