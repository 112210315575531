@import ../../../styles/helpers

.tradeCryptoCard
    display: flex
    flex-direction: column
    gap: 16px
    text-align: center
    width: 100%
    background-color: $n6
    padding: 36px 50px
    border-radius: 20px
    +dark
        background-color: $n2

.button
    align-self: center
    width: fit-content
    background-color: $n4
    color: $white
    padding: 10px
    border-radius: 20px



