@import ../../../styles/helpers

.whyChooseFinitixContainer
    display: flex
    justify-content: space-between
    align-items: center
    gap: 86px
    +t
        flex-direction: column

.content
    display: flex
    flex-direction: column
    gap: 24px
    justify-content: start
    width: 100%

.accordionSummary
    display: flex
    justify-content: space-between
    padding: 10px 23px 10px 32px
    border-radius: 10px
    cursor: pointer
    background-color: #F4F5F6
    +dark
        background-color: #23262E
    &.active 
        background-color: #3772FF
        color: $white

.accordionTitle
    font-weight: 700
    font-size: 16px
    line-height: 24px

.accordionDetails
    text-align: left
    color: $n4
    padding-left: 6px

.accordion
    display: flex
    flex-direction: column
    gap: 16px

.image
    height: 594px
    +t
        width: 368px
        height: auto