// common styles 
@import ./reset
@import ./helpers
@import ./common

// global classes
@import ./blocks/section
@import ./blocks/container
@import ./blocks/title
@import ./blocks/category
@import ./blocks/button
@import ./blocks/stage
@import ./blocks/favorite
@import ./blocks/sorting
@import ./blocks/slick
@import ./blocks/datepicker
@import ./blocks/twofa

html
  scroll-behavior: smooth