@import ../../../styles/helpers

.main
    position: relative
    padding-top: 156px
    min-height: 503px
    color: #000000
    +m
        min-height: auto
        padding: 64px 0 112px
    +dark
        background: #18191D

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    margin-bottom: 143px
    +m
        margin-bottom: 8px

.title
    margin-bottom: 32px
    color: #000000
    +m
        margin-bottom: 16px
        font-size: 40px
        line-height: 48px
    +dark
        color: white

.text
    +body-1
    color: #000000
    +m
        font-size: 16px
    +dark
        color: white
    strong
        font-weight: 600

.bg
    position: absolute
    bottom: 0
    right: 80px
    pointer-events: none
    height: 439px
    +d
        right: 48px
    +m
        position: static
        width: auto
        margin: 0 -76px 0 -30px
    img
        width: 100%