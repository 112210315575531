@import ../../../styles/helpers

.active
    .head
        svg
            color: $n2
            +dark
                color: $n6
    .body
        visibility: visible
        opacity: 1
        transform: translateY(0)

.head
    position: relative
    width: 40px
    height: 40px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    margin-right: 24px
    svg
        color: $n4
        transition: color .2s
    &:hover
        svg
            color: $n2
            +dark
                color: $n6

.layoutContainer
    position: absolute
    background-color: rgba(0, 0, 0, 50%)
    height: 100vh
    width: 100vw
    top: 0
    left: 0
    overflow: hidden
    display: flex
    justify-content: flex-end
    z-index: 1

.layoutSidebar
    padding: 16px
    width: 320px
    height: 100vh
    display: flex
    flex-direction: column
    gap: 16px
    background-color: $n6
    +dark
        background-color: $n2

.headerTitle
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    padding-left: 8px
    color: $n2
    +dark
        color: $n6
    .head
        margin-right: 0

.layoutOptions
    display: flex
    align-items: center
    justify-content: space-around

.layoutItem
    display: flex
    align-items: center
    gap: 8px
    flex-direction: column
    cursor: pointer 

.activeLayout
    border: 1px solid $n4
    border-radius: 6px
